

function App() {
  return (
    <>
      <div>HELLO BYE</div>
    </>
  );
}

export default App;
